import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import router from './router'
// register modal component
import mitt from 'mitt'
import Unicon from 'vue-unicons'
import { uniPhoneTimes, uniClockTen, uniCalendarAlt, uniUniversity, uniGraduationCap, uniMapMarkerAlt, uniMapMarker, uniStethoscope,uniUserCircle, uniPhone,uniMoneyBill, uniCommentInfo, uniImageResizeLandscape,uniScenery, uniSchedule  } from 'vue-unicons/dist/icons'

Unicon.add([uniPhoneTimes, uniClockTen, uniCalendarAlt, uniUniversity, uniGraduationCap, uniMapMarkerAlt, uniMapMarker, uniStethoscope,uniUserCircle, uniPhone,uniMoneyBill, uniCommentInfo, uniImageResizeLandscape,uniScenery, uniSchedule ])
const emitter = mitt()
const app = createApp(App);
app.component("modal", {
  template: "#modal-template"
});
app.provide('imgUrl', 'https://storage.googleapis.com/mypaaa-e3da0.appspot.com/')
app.provide('appUrl', 'https://s.mypaaa.com/')
app.provide('appDownloadUrl', 'http://onelink.to/pgyk4h')
app.use(router)
app.use(Unicon)
app.config.globalProperties.emitter = emitter
app.mount('#app')
