import { createWebHistory, createRouter } from "vue-router";

const routes =  [
  {
    path: "/d/:id",
    name: "detailsPage",
    component: () => import("./components/DetailsPage")
  },
  {
    path: "/o/:id",
    name: "organization-details",
    component: () => import("./components/OrganizationDetails")
  },
  {
    path: "/e/:id",
    name: "event-details",
    component: () => import("./components/DetailsEventPage")
  },
  {
    path: "/ep/:id",
    name: "public-event",
    component: () => import("./components/DetailsPublicEventPage")
  },
  {
    path: "/sq/:id",
    name: "queue-event",
    component: () => import("./components/DetailsQueuePage")
  },
  {
    path: "/c/:id",
    name: "calander-event",
    component: () => import("./components/DetailsCalanderPage")
  },
  {
    path: "/p/:id",
    name: "profile-details",
    component: () => import("./components/ProfileDetailsPage")
  },
  {
    path: "/q/:id",
    name: "profile-details",
    component: () => import("./components/ProfileDetailsPage")
  },
  {
    path: "/add",
    name: "add",
    component: () => import("./components/AddTutorial")
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;