<template>
  <div id="app">
    <nav class="navbar navbar-expand navbar-dark bg-dark">
      <img  src="https://bo.mypaaa.com/img/apple-icon.png" width="75" class="thumbnail fluid" /> 

      <router-link to="/" class="navbar-brand p-3">{{title}}</router-link>
      <div class="navbar-nav mr-auto">
        
        
      </div>
    </nav>

    <div class="container mt-3">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "app",
  data(){
return {title:null}
  },
  mounted() {
    this.emitter.on('updateTitle', (title) => {
      this.title = title;
    })
  }
};
</script>
